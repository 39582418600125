@import "~bootswatch/dist/darkly/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/darkly/bootswatch";

$navbar-height: 54px;

$navbar-background: theme-color('light');
.navbar {
    @extend .navbar-light;
    background-color: $navbar-background;
}
$callstatus-foreground: $white;

$subform-card-color: darken(theme-color('light'), 3%);

@import '../main.scss';
